import React from 'react'

const UploadIcon = () => {
  return (
    <svg
      width='63'
      height='51'
      viewBox='0 0 63 51'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M38.352 39.5767H33.2098V22.0229C33.2098 21.7042 32.96 21.4434 32.6547 21.4434H28.491C28.1856 21.4434 27.9358 21.7042 27.9358 22.0229V39.5767H22.8075C22.3426 39.5767 22.0858 40.1345 22.3703 40.5113L30.1426 50.7769C30.1945 50.8462 30.2608 50.9022 30.3366 50.9407C30.4123 50.9793 30.4955 50.9993 30.5797 50.9993C30.664 50.9993 30.7472 50.9793 30.8229 50.9407C30.8986 50.9022 30.965 50.8462 31.0169 50.7769L38.7892 40.5113C39.0737 40.1345 38.8169 39.5767 38.352 39.5767Z'
        fill='black'
        fillOpacity='0.3'
      />
      <path
        d='M51.8658 14.9746C48.6876 6.22313 40.5892 0 31.1029 0C21.6166 0 13.5182 6.21589 10.3399 14.9674C4.39271 16.5974 0 22.2555 0 28.9785C0 36.9838 6.21085 43.4677 13.8721 43.4677H16.6548C16.9601 43.4677 17.21 43.2069 17.21 42.8882V38.5414C17.21 38.2226 16.9601 37.9618 16.6548 37.9618H13.8721C11.5335 37.9618 9.33363 36.9911 7.69591 35.2306C6.06512 33.4774 5.19769 31.1157 5.27402 28.667C5.33648 26.7544 5.96103 24.9577 7.09217 23.4436C8.25107 21.9005 9.87491 20.7776 11.6792 20.2777L14.3093 19.5605L15.2738 16.909C15.8706 15.2572 16.7034 13.7141 17.7512 12.3159C18.7857 10.93 20.0111 9.71178 21.3875 8.70079C24.2397 6.6071 27.5984 5.49867 31.1029 5.49867C34.6073 5.49867 37.966 6.6071 40.8182 8.70079C42.1991 9.71504 43.4205 10.9321 44.4545 12.3159C45.5023 13.7141 46.3351 15.2644 46.9319 16.909L47.8895 19.5532L50.5126 20.2777C54.2739 21.3354 56.9039 24.907 56.9039 28.9785C56.9039 31.3765 56.0087 33.6368 54.3849 35.332C53.5885 36.1683 52.6412 36.8313 51.5977 37.2827C50.5543 37.7341 49.4354 37.9649 48.3059 37.9618H45.5231C45.2178 37.9618 44.968 38.2226 44.968 38.5414V42.8882C44.968 43.2069 45.2178 43.4677 45.5231 43.4677H48.3059C55.9671 43.4677 62.1779 36.9838 62.1779 28.9785C62.1779 22.2627 57.7991 16.6119 51.8658 14.9746Z'
        fill='black'
        fillOpacity='0.3'
      />
    </svg>
  )
}

export default UploadIcon
